<template>
  <b-container class="py-md-4">
    <LoadingSpinner v-if="loading" />
    <div class="register_wrapper my-md-5 my-4 p-3'">
      <b-row class="justify-content-center">
        <b-col
          xl="10"
          lg="10"
        >
          <p class="display-4 font-weight-bold mt-4 pb-4">
            Payments
          </p>
          <b-container class="mt-5">
            <b-row :class="[style['detail-wrapper'], 'py-3']">
              <b-col
                class="ml-3"
                lg="9"
              >
                <p class="font-weight-bold mb-0 mt-3 h4">
                  Total Payable Amount
                </p>
              </b-col>
              <b-col class="font-weight-bold h3 mt-3">
                ${{ parseFloat(amount).toFixed(2) }}
              </b-col>
            </b-row>
          </b-container>
          <div
            ref="paypal"
            class="mt-3"
            style="margin-left: 25%;width: 50%;text-align: center;justify-content: center;"
          />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import style from '../../styles/bookings.module.scss';
import paypal from '../../assets/images/paypall.png';
import {PAYPAL_CLIENT_ID} from '../../config/config';
import { createOrderApi, captureOrder } from '../../api/shopping';
import { addOrder, addOrderItems } from '../../api/bookings';
import LoadingSpinner from '../Common/LoadingSpinner';
export default {
    name: 'Checkout',
    components:{ LoadingSpinner },
    props:{
        next:{
            type: Function,
            default:() => {}
        },
        type:{
            type:String,
            default:""
        }
    },
    data(){
        return {
            style,
            paymentMethod:"",
            paypal,
            amount:null,
            shipping:null,
            orderId:null,
            cartItems: localStorage.getItem('productsList'),
            cartIds:[],
            username: localStorage.getItem('username'),
            loading: false,
        };
    },
    mounted(){
        const script = document.createElement('script');
        script.src =
      'https://www.paypal.com/sdk/js?client-id='+PAYPAL_CLIENT_ID+'&components=buttons,hosted-fields,funding-eligibility';
        script.addEventListener('load', this.setLoaded);
        document.body.appendChild(script);
    },
    created(){
        this.amount = this.$route.query.amount !== undefined ? this.$route.query.amount : 0;
        this.shipping = this.$route.query.shipping !== undefined ? this.$route.query.shipping : 0;
    },
    methods:{
        handlePayment(event){
            this.paymentMethod = event.target.value;
        },
        setLoaded:function(){
            window.paypal
                .Buttons({
                    createOrder: async () =>{
                        this.loading = true;
                        let orderCreated = await createOrderApi(this.amount);
                        if (orderCreated.status === 'CREATED') {
                            this.loading = false;
                            this.order_id = orderCreated.id;
                            return orderCreated.id;
                        }
                    },
                    onApprove: async () => {
                        const order = await captureOrder(this.order_id);
                        if (order.status){
                            let paymentResponse = order.data;
                            let paypal_order_id = this.order_id;
                            let paypal_capture_id = paymentResponse.purchase_units[0].payments.captures[0].id;
                            let paypal_status = paymentResponse.status;
                            let recieptId = paymentResponse.purchase_units[0].payments.captures[0].id;
                            this.loading = true;
                            let ordered = await addOrder(this.amount, recieptId, paymentResponse, this.shipping,paypal_order_id,paypal_capture_id,paypal_status);
                            if (ordered.id !== undefined){
                                this.loading = false;
                                this.orderId = ordered.id;
                                JSON.parse(this.cartItems).map(value=>{
                                    this.cartIds.push(value.id);
                                });
                                this.loading = true;
                                let ItemsOrdered = await addOrderItems(this.cartIds, this.orderId );
                                if (ItemsOrdered.status){
                                    this.loading = false;
                                    localStorage.setItem('totalProducts', 0);
                                    document.getElementById('badge').style.opacity = 0;
                                    this.$router.push(`/feed/${this.username}`);
                                }
                            }
                        }
                    },
                }).render(this.$refs.paypal);
        }
    }
};
</script>
